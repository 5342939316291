import { PublicClientApplication } from '@azure/msal-browser';

const serverUrl =
  window.location.protocol +
  '//' +
  window.location.hostname +
  (window.location.port && window.location.port !== 80 && window.location.port !== 443
    ? ':' + window.location.port
    : '') +
  '/';
// Configuration for Zeiss ID
export const msalConfig = {
  auth: {
    clientId: '5d43f077-041f-4e13-a4f6-e52fcf5057e7',

    redirectUri: serverUrl,
    postLogoutRedirectUri: serverUrl,

    authority: 'https://id-ip-stage.zeiss.com/accounts-stage.zeiss.com/B2C_1A_ZeissIdNormalSignIn',

    knownAuthorities: ['id-ip-stage.zeiss.com'],
    validateAuthority: true,

    navigateToLoginRequestUrl: true,
    authorityMetadata: `{

            "issuer":"https://b2cstage.zeiss.com/483f449b-6a0d-4354-b7e7-37113e7d4ca1/v2.0/",
            "authorization_endpoint":"https://id-ip-stage.zeiss.com/OAuth/Authorize?p=B2C_1A_ZeissIdNormalSignIn",
            "token_endpoint":"https://id-ip-stage.zeiss.com/OAuth/token?p=B2C_1A_ZeissIdNormalSignIn",
            "end_session_endpoint":"https://id-ip-stage.zeiss.com/oauth/logout?p=B2C_1A_ZeissIdNormalSignIn",
            "jwks_uri":"https://b2cstage.zeiss.com/accounts-stage.zeiss.com/discovery/v2.0/keys?p=b2c_1a_zeissidnormalsignin",
            "response_modes_supported":["query","fragment","form_post"],
            "response_types_supported":["code","code id_token","code token","code id_token token","id_token","id_token token","token","token id_token"],
            "scopes_supported":["openid"],
            "subject_types_supported":["pairwise"],
            "id_token_signing_alg_values_supported":["RS256"],
            "token_endpoint_auth_methods_supported":["client_secret_post","client_secret_basic"],
            "claims_supported":["oid","sub","email","sub","ZeissIdBase","ZeissIdContact","ZeissIdOrganisation","ZeissIdApplication","ZeissIdCustomFields","ZeissIdAgreements","getMoreClaims","iss","iat","exp","aud","acr","nonce","auth_time"]}`,
  },
  cache: {
    // This configures where your cache will be stored

    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback(level, message, containsPii) {
        if (containsPii) {
          return;
        }

        switch (level) {
          case 0:
            console.error(message);
            return;
          case 2:
            // console.info(message);
            return;
          case 3:
            console.debug(message);
            return;
          case 1:
            console.warn(message);
            return;
          default:
            console.error(message);
        }
      },
      piiLoggingEnabled: true,
    },
  },
};

export const loginRequest = {
  scopes: ['openid 5d43f077-041f-4e13-a4f6-e52fcf5057e7'],
  forceRefresh: false,
};

export const msalInstance = new PublicClientApplication(msalConfig);
