import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

export const DialogContainer = styled.div<{ $open: boolean }>`
  position: fixed;
  z-index: ${(props) => (props.$open ? 100 : -1)};
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 50%);
  display: ${(props) => (props.$open ? 'flex' : 'none')};
`;

const DialogWrapper = styled.div`
  display: inherit;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
`;

export interface ModalsContainerProps {
  /**
   * Gets if the modal is currently open or closed.
   */
  open: boolean;
  /**
   * The dialogs to display.
   */
  children?: ReactNode;
}

export const ModalContainer: FC<ModalsContainerProps> = ({ children, open }) => (
  <DialogContainer $open={open}>
    <DialogWrapper>{children}</DialogWrapper>
  </DialogContainer>
);
